<template>
  <form @submit.prevent="SignUp">
    <div class="flex flex-col gap-y-3 mx-auto max-w-xs">
      <input
        type="email"
        v-model="registerForm.email"
        required
        class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
        placeholder="Email@example.com"
      />
      <input
        type="text"
        v-model="registerForm.firstname"
        required
        class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
        :placeholder="$t('firstname')"
      />
      <input
        type="text"
        v-model="registerForm.lastname"
        required
        class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
        :placeholder="$t('lastname')"
      />
      <input
        type="password"
        required
        v-model="registerForm.password"
        class="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200/50 bg-white dark:bg-neutral-800 rounded-full text-sm font-normal h-11 px-4 py-3 mt-1"
        :placeholder="$t('your_password')"
      />
      <div v-if="err_msg" class="text-xs text-center w-full mx-auto text-red-500 flex mt-2 mb-4">
        ⚠️ {{ err_msg }}
      </div>
      <ElementsButtonSubmit :loading="loading"> {{$t('sign_up')}}</ElementsButtonSubmit>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useConfigStore } from "~/stores/config";
const { auth_modal } = storeToRefs(useConfigStore());
const props = defineProps({
  redirect: {
    type: Boolean,
    default: false,
  },
});
const loading = ref(false)
const err_msg = ref("");
const SignUp = () => {
  loading.value = true
  storeAuth
  .register(registerForm.value)
  .then((res) => {
      loading.value = false
      auth_modal.value = false;
      if (props.redirect) {
        navigateTo('/dashboard')
      }
    })
    .catch((err) => {
      loading.value = false
      if (err.data.message) {
        err_msg.value = err.data.message;
      }
    });
};
const storeAuth = useAuthStore();
const { registerForm } = storeToRefs(storeAuth);
</script>
